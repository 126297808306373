import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import siteIcon from "../images/icon.png"

function Seo({ pathname, description, title }) {
	const {
		site: {
			siteMetadata: { siteUrl, siteTitle, twitter },
		},
	} = useStaticQuery(graphql`
		query SiteMetadata {
			site {
				siteMetadata {
					siteUrl
					twitter
				}
			}
		}
	`)

	return (
		<Helmet
			defer={false}
			defaultTitle={`Emoji Maker - Design Custom Emojis for Free`}
			titleTemplate={`%s`}
			title={title}
		>
			<html lang="en-US" />
			<link rel="canonical" href={`${siteUrl}${pathname}`} />
			<meta
				name="viewport"
				content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
			/>
			<meta
				name="description"
				content={description ? description : `Create custom Twitter emojis.`}
			/>
			<meta property="og:url" content={siteUrl} />
			<meta property="og:type" content="website" />
			<meta property="og:locale" content="en" />
			<meta property="og:site_name" content={siteTitle} />
			<meta property="og:image" content={`${siteUrl}${siteIcon}`} />
			<meta property="og:image:width" content="512" />
			<meta property="og:image:height" content="512" />

			<meta name="twitter:card" content="summary" />
			<meta name="twitter:site" content={twitter} />
		</Helmet>
	)
}

export default Seo
