import React from "react"
import { Link } from "gatsby"

function Header({ isHome = false }) {
	return (
		<header className="mx-auto container px-6 py-4">
			<div className="flex items-center h-10">
				<div className="flex-grow">
					<a className="flex items-center" href="https://flat-icons.com/">
						<span>
							<img
								className="logo"
								src={require("../images/logo.inline.svg")}
								alt="Jamstack Studio"
							/>
						</span>
					</a>
				</div>
				<div className="hidden sm:block font-semibold text-gray-600 text-sm">
					<Link
						className="px-2 bg-blue-900 text-white py-2 rounded"
						to={isHome ? "/best-emojis" : "/"}
					>
						{isHome ? "Best Emojis" : "Emoji Maker"}
					</Link>
				</div>
			</div>
		</header>
	)
}

export default Header
