import React from 'react'

function Footer({ children }) {
	return (
		<footer>
			<p className='pt-16 pb-8 text-center text-gray-700'>
				A project by{` `}
				<a className='font-semibold text-blue-600' href='https://imedadel.me'>
					Imed Adel
				</a>
				.
			</p>
			{children}
		</footer>
	)
}

export default Footer
